import isEmpty from "is-empty";
import moment from "moment";
import React, { useEffect, useState, Suspense, useCallback } from "react";
import {
  Accordion,
  FloatingLabel,
  Form,
  FormControl,
  InputGroup,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { ConfirmModal } from "../Components/Modal/ConfirmModal";
import { CustomModal } from "../Components/Modal/CustomModal";
import { ToastMessage } from "../Components/Toast";

import {
  allMarkets,
  allSeries,
  allVertialSeries,
  marketBetEnableDisabled,
  marketEnableDisabled,
  matchBetEnableDisabled,
  matchEnableDisabled,
  seriesBetEnableDisabled,
  seriesEnableDisabled,
  setMarketMaxMinBet,
  setMatchMaxMinBet,
  setSeriesMaxMinBet,
  settleMarket,
} from "./Service";
import AllMarketDataComponent from "./AllMarketData";
import { useHistory, useLocation } from "react-router";
import Loader from "../Components/Spinner";
import { IoSearchSharp } from "react-icons/io5";
import {
  searchEvent,
  searchEventSoccer,
  searchEventTennis,
  searchEventVirtual,
} from "../Components/layout/Service";
import toast from "react-hot-toast";

const marketNameTemp = "Match Odds";

export default function EventManagement() {
  let history = useHistory();
  const location = useLocation();
  const [sportData, setSportData] = useState([]);
  const [showConfrimModal, setShowConfirmModal] = useState(false);
  const [showConfrimMatchModal, setShowConfirmMatchModal] = useState(false);
  const [confirmSeriesDetails, setConfirmSeriesDetails] = useState("");
  const [confirmMatchDetails, setConfirmMatchDetails] = useState("");
  const [allMarketData, setAllMarketData] = useState([]);
  const [showEditBetModal, setShowEditBetModal] = useState(false);
  const [editBetData, setEditBetData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isBetLockStatus, setIsBetLockStatus] = useState(false);
  const [isMatchBetLockStatus, setIsMatchBetLockStatus] = useState(false);
  const [isMarketBetLockStatus, setIsMarketBetLockStatus] = useState(false);
  const [search, setSearch] = useState("");
  const [isSeriesSetPrice, setIsSeriesSetPrice] = useState(false);
  const [isMatchSetPrice, setIsMatchSetPrice] = useState(false);
  const [errorMessage, setErroreMessage] = useState("");
  const [showMarketSettelModal, setShowMarketSettelModal] = useState(false);
  const [settelmentData, setSettelmentData] = useState({});
  const [isSessionPassed, setIsSessionPassed] = useState(false);
  const [winnerData, setWinnerData] = useState({});
  const [marketSettlmentType, setMarketSettlmentType] = useState("");
  const [matchRestult, setMatchResult] = useState("");
  const [selectedMatchId, setSelectedMatchId] = useState("");
  const [key, setKey] = useState("unsettled");
  const [isDataLoading, setDataLoading] = useState(false);
  const [cricketData, setCricketData] = useState({});

  const [eventNameQuery, setEventNameQuery] = useState("");
  const handleCloseMarketSettelModal = () => setShowMarketSettelModal(false);
  const handleOpenMarketSettelModal = (data, eventId, type, matchData) => {
    setShowMarketSettelModal(true);
    console.log(data, "checkData");
    setSettelmentData((prev) => ({ ...prev, ...data, eventId }));
    setMarketSettlmentType(type);
    setSelectedMatchId(matchData.matchId);
  };

  const handleCloseConfirmModal = () => setShowConfirmModal(false);
  const handleOpenConfirmModal = () => setShowConfirmModal(true);
  const handleCloseConfirmMatchModal = () => setShowConfirmMatchModal(false);
  const handleOpenConfirmMatachModal = () => setShowConfirmMatchModal(true);
  const handleCloseEditBetModal = () => setShowEditBetModal(false);

  const handleOpenEditBetModal = (id, type) => {
    if (type == "EVENT_MIN_MAX") {
      setEditBetData((prevState) => ({
        ...prevState,
        matchId: id,
        event: "EVENT_MIN_MAX",
      }));
    }
    if (type === "SERIES") {
      setIsSeriesSetPrice(true);
      setIsMatchSetPrice(false);
      setEditBetData((prevState) => ({ ...prevState, seriesId: id }));
    } else if (type === "MATCH") {
      setIsMatchSetPrice(true);
      setIsSeriesSetPrice(false);
      setEditBetData((prevState) => ({ ...prevState, matchId: id }));
    } else {
      setIsSeriesSetPrice(false);
      setIsMatchSetPrice(false);
      setEditBetData((prevState) => ({ ...prevState, marketId: id }));
    }

    setShowEditBetModal(true);
  };

  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);

  const getAllSeries = useCallback(async () => {
    let res;
    if (location.pathname.includes("t10")) {
      res = await allVertialSeries(key === "settled" ? true : false);
    } else {
      res = await allSeries(key === "settled" ? true : false);
    }

    console.log(res, "resDataR");
    if (res.code === 200) {
      //   onSubmitUserHandler(res.response);
      setSportData(res.response);
      setDataLoading(false);
    }
  }, [key, location]);

  useEffect(() => {
    getAllSeries();
  }, [getAllSeries]);

  const confirmStatusUpdate = (data, type) => {
    console.log(data, type, "test");
    if (type === "BET") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.betLock ? "Enabled Bet" : "Disabled Bet",
        seriesName: data.competitionsName,
        seriesId: data.seriesId,
        betEnable: !data.betLock,
      }));
    } else if (type === "MATCH_BET_STATUS") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled Bet" : "Disabled Bet",
        matchName: data.eventName,
        matchId: data.id,
        betEnable: !data.betLock,
        event: type,
      }));
    } else if (type == "MATCH_STATUS") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        matchName: data.eventName,
        eventId: data.id,
        enabled: !data.enabled,
        event: type,
      }));
    } else if (type === "MARKET") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled Bet" : "Disabled Bet",
        matchName: data.eventName,
        marketId: data.mktId,
        betEnable: !data.betLock,
      }));
    } else if (type === "MARKET_ENABLE") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        seriesName: data.competitionsName,
        marketId: data.mktId,
        isEnable: !data.enabled,
      }));
    } else if (type === "Series_BetStatus") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        seriesName: data.competitionsName,
        seriesId: data.seriesId,
        isEnable: !data.enabled,
      }));
    } else {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        seriesName: data.competitionsName,
        seriesId: data.seriesId,
        isEnable: !data.enabled,
      }));
    }
  };

  const handleSwitchChange = (e, data, type) => {
    e.stopPropagation();
    console.log(type, "type");
    if (type === "MATCH_STATUS") {
      confirmStatusUpdate(data, "MATCH_STATUS");
    } else if (type === "MATCH_BET_STATUS") {
      confirmStatusUpdate(data, "MATCH_BET_STATUS");
    } else {
      confirmStatusUpdate(data, type);
    }

    handleOpenConfirmModal();

    /* if (type === "Series_BetStatus") {
      setIsMarketBetLockStatus(false);
      handleOpenConfirmModal();
      confirmStatusUpdate(data, "BET");
      setIsMarketEnableStatus(false);

      setIsBetLockStatus(true);
      setIsMatchBetLockStatus(false);
    } else if (type === "Series_Enable") {
      setIsMarketBetLockStatus(false);
      handleOpenConfirmModal();
      setIsBetLockStatus(false);
      setIsMarketEnableStatus(false);

      setIsMatchBetLockStatus(false);
      if (data.enabled) {
        confirmStatusUpdate(data);
      } else {
        confirmStatusUpdate(data);
      }
    } else if (type === "Market_Enable") {
      setIsMarketBetLockStatus(false);
      handleOpenConfirmModal();
      setIsBetLockStatus(false);
      setIsMatchBetLockStatus(false);
      setIsMarketEnableStatus(true);
      confirmStatusUpdate(data, "MARKET_ENABLE");
    } else if (type === "Market_BetStatus") {
      setIsMarketBetLockStatus(true);
      setIsMatchBetLockStatus(false);
      setIsMarketEnableStatus(false);
      setIsBetLockStatus(false);
      confirmStatusUpdate(data, "MARKET");
      handleOpenConfirmMatachModal();
    } else if (type === "Match_BetStatus") {
      setIsMarketBetLockStatus(false);
      setIsMatchBetLockStatus(true);
      setIsBetLockStatus(false);
      setIsMarketEnableStatus(false);
      confirmStatusUpdate(data, "MATCH");
      handleOpenConfirmMatachModal();
    } */
  };
  const handleSwitchMatchChange = (e, data) => {
    e.stopPropagation();
    console.log(data, "checkData");
    handleOpenConfirmMatachModal();
    if (data.enabled) {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: "Enabled",
        matchName: data.eventName,
        matchId: data.matchId,
        isEnabled: data.enabled,
      }));
    } else {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: "Disabled",
        matchName: data.eventName,
        matchId: data.matchId,
        isEnabled: !data.enabled,
      }));
    }
  };

  const seriesEnableDisabledHandler = async () => {
    setDataLoading(true);
    handleCloseConfirmModal();
    const { enabled, eventId, betEnable, marketId, event, matchId } =
      confirmMatchDetails;

    let res;
    switch (event) {
      case "MATCH_STATUS":
        res = await matchEnableDisabled(eventId, enabled);
      case "MATCH_BET_STATUS":
        res = await matchBetEnableDisabled(matchId, betEnable);
    }
    /* if (isBetLockStatus) {
      res = await seriesBetEnableDisabled(seriesId, betEnable);
    } else if (isMarketEnableStatus) {
      res = await marketEnableDisabled(marketId, isEnable);
    } else {
      res = await seriesEnableDisabled(seriesId, isEnable);
    } */

    getAllSeries();

    console.log(res, "resConfirm");
  };
  const handleConfirm = async () => {
    const { isEnabled, eventId, betEnable, marketId, matchId, event, enabled } =
      confirmMatchDetails;
    let res;
    console.log(event, "event");
    if (event === "MATCH_STATUS") {
      res = await matchEnableDisabled(eventId, enabled);
    }
    if (event === "MATCH_BET_STATUS") {
      res = await matchBetEnableDisabled(matchId, betEnable);
    }

    getAllSeries();
    setShowConfirmModal(false);
  };

  const allMarketsHandler = async (id) => {
    const res = await allMarkets(id);

    if (res.code === 200) {
      setAllMarketData(res.response);
    }

    console.log(res, "resData");
  };

  const handleMarketEditChange = (e) => {
    const { name, value } = e.target;
    setErroreMessage("");

    setEditBetData((prevState) => {
      console.log(prevState, "checkPRev");
      return { ...prevState, [name]: value };
    });
  };

  const onSubmitBetHandler = async () => {
    const { maxMarket, minMarket, marketId, seriesId, matchId } = editBetData;
    if (parseInt(minMarket) > parseInt(maxMarket)) {
      setErroreMessage("Invalid input!");
      return;
    } else {
      setErroreMessage("");
    }
    let res;
    switch (editBetData.event) {
      case "EVENT_MIN_MAX":
        res = await setMatchMaxMinBet(maxMarket, minMarket, matchId);
        break;
    }
    /*  if (isSeriesSetPrice) {
      res = await setSeriesMaxMinBet(maxMarket, minMarket, seriesId);
    } else if (isMatchSetPrice) {
      res = await setMatchMaxMinBet(maxMarket, minMarket, matchId);
    } else {
      res = await setMarketMaxMinBet(maxMarket, minMarket, marketId);
    } */

    if (res.code === 200) {
      getAllSeries();
      handleShowToast();
      toast.success("Action performed successfully!");
      handleCloseEditBetModal();
    }

    console.log(res, "resStatus");
  };

  const handleSwitchSettelChange = (e) => {
    e.stopPropagation();

    setIsSessionPassed(e.target.checked);
  };

  const handleChangeSettel = (e) => {
    const { name, value } = e.target;
    setSettelmentData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeWinner = (e) => {
    const { value } = e.target;
    console.log(value);
    if (value === "abandoned" || value === "tie") {
      return setMatchResult(value);
    } else {
      setMatchResult("");
    }
    const selectionId = value.split("-")[0];
    const winnerName = value.split("-")[1];
    setWinnerData((prev) => ({
      ...prev,
      selectionId: selectionId,
      winnerName,
    }));
  };

  const settleMarketHandler = async () => {
    const { marketName, eventId, marketId, comment } = settelmentData;
    const { selectionId, winnerName } = winnerData;

    //settle or rollback

    const payload = {
      eventId,
      marketId,
      marketName,
      settlementComment: comment,
      winnerName: winnerName,
      winnerSelectionId: selectionId,
      eventType: marketSettlmentType,
    };
    if (!isEmpty(matchRestult)) {
      payload[matchRestult] = true;
      delete payload.winnerName;
    }

    if (!marketName.includes(marketNameTemp)) {
      payload.isSessionPassed = isSessionPassed;
      delete payload.winnerName;
      delete payload.winnerSelectionId;
    }

    const res = await settleMarket(payload);

    console.log(res, "resData");
    handleCloseMarketSettelModal();
    handleShowToast();
    setToastMessage(res.message);
    setSettelmentData({});
    setSettelmentData({});
    setIsSessionPassed(false);
    //selectedMatchId
    allMarketsHandler(selectedMatchId);
  };
  const handleEventChange = (e) => {
    if (e.key === "Enter") {
      searchEventHanlder();
    }
    setEventNameQuery(e.target.value);
  };
  const searchEventHanlder = async () => {
    let res;
    if (location.pathname.includes("event-management/cricket")) {
      res = await searchEvent(eventNameQuery);
    } else if (location.pathname.includes("event-management/t10")) {
      res = await searchEventVirtual(eventNameQuery);
    }
    if (location.pathname.includes("event-management/soccer")) {
      res = await searchEventSoccer(eventNameQuery);
    } else if (location.pathname.includes("event-management/tennis")) {
      res = await searchEventTennis(eventNameQuery);
    }

    if (res.code === 200) {
      setSportData(res.response);
    }
  };

  console.log(confirmSeriesDetails, "confirmSeriesDetails");
  return (
    <>
      <InputGroup
        style={{
          width: "50%",
          margin: 10,
          marginLeft: "auto",
        }}
      >
        <FormControl
          placeholder="Search..."
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          onChange={handleEventChange}
        />
        <InputGroup.Text
          id="basic-addon2"
          onClick={searchEventHanlder}
          style={{ cursor: "pointer" }}
        >
          <IoSearchSharp />
        </InputGroup.Text>
      </InputGroup>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
        style={{ marginTop: "-40px" }}
      >
        <Tab eventKey="settled" title="Settled">
          {isDataLoading ? (
            <Loader />
          ) : (
            <>
              {sportData.map((c, i) => (
                <Accordion defaultActiveKey={0} key={c.gameId}>
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header
                      onClick={() => {
                        // allMarketsHandler(match.matchId)
                        window.location.href = `/dashboard/all-market/cricket/${c.eventName}/${c.eventId}/${c.id}`;
                      }}
                    >
                      <p className="overflow-text">{c.eventName}</p>
                      <p className="overflow-text">
                        {moment(c.eventTime)
                          .utc()
                          .format("DD-MM-YYYY hh:mm:ss A")}
                      </p>
                      <div className="header_wrap">
                        <h6
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditBetModal(c.gameId, "SERIES");
                          }}
                        >
                          {c.minStack ? c.minStack : "0"} -{" "}
                          {c.maxStack ? c.maxStack : "0"}
                        </h6>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.betLock ? "Bet Locked" : "Bet Unlocked"}
                          checked={c.betLock}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "Series_BetStatus")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.enabled ? "Enabled" : "Disabled"}
                          checked={c.enabled}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "Series_Enable")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </Accordion.Header>
                    {/*  <Accordion.Body>
                      {c.cricketChildEvents.map((match, index) => (
                        <>
                          <Accordion key={match.matchId}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header
                                onClick={() => {
                                  // allMarketsHandler(match.matchId)
                                  window.location.href = `/dashboard/all-market/cricket/${match.eventName}/${match.eventId}/${match.matchId}`;
                                }}
                              >
                                {match.eventName} -{" "}
                                {moment(match?.startDate).format(
                                  "Do MMM, h:mm: a"
                                )}
                                <div className="header_wrap">
                                  <h6
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOpenEditBetModal(
                                        c.seriesId,
                                        "MATCH"
                                      );
                                    }}
                                  >
                                    {match.minStack ? match.minStack : "0"} -{" "}
                                    {match.maxStack ? match.maxStack : "0"}
                                  </h6>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label={
                                      match.betLock
                                        ? "Bet Locked"
                                        : "Bet Unlocked"
                                    }
                                    checked={match.betLock}
                                    onChange={(e) =>
                                      handleSwitchChange(
                                        e,
                                        match,
                                        "Match_BetStatus"
                                      )
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label={
                                      match.enabled ? "Enabled" : "Disabled"
                                    }
                                    checked={match.enabled}
                                    onChange={(e) =>
                                      handleSwitchMatchChange(e, match)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </div>
                              </Accordion.Header>
                            </Accordion.Item>
                          </Accordion>
                        </>
                      ))}
                    </Accordion.Body> */}
                  </Accordion.Item>
                </Accordion>
              ))}
            </>
          )}
        </Tab>
        <Tab eventKey="unsettled" title="Unsettled">
          {isDataLoading ? (
            <Loader />
          ) : (
            <>
              {sportData.map((c, i) => (
                <Accordion defaultActiveKey={0} key={c.gameId}>
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header
                      onClick={() => {
                        // allMarketsHandler(match.matchId)
                        window.location.href = `/dashboard/all-market/cricket/${c.eventName}/${c.eventId}/${c.id}`;
                      }}
                    >
                      {c.eventName +
                        " " +
                        moment(c.eventTime + "Z")
                          .utc()
                          .format("DD-MM-YYYY hh:mm:ss A")}

                      <div className="header_wrap">
                        <h6
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditBetModal(c.id, "EVENT_MIN_MAX");
                          }}
                        >
                          {c.minStack ? c.minStack : "0"} -{" "}
                          {c.maxStack ? c.maxStack : "0"}
                        </h6>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.betLock ? "Bet Locked" : "Bet Unlocked"}
                          checked={c.betLock}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "MATCH_BET_STATUS")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.enabled ? "Enabled" : "Disabled"}
                          checked={c.enabled}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "MATCH_STATUS")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </Accordion.Header>
                    {/* <Accordion.Body>
                      {c.cricketChildEvents.map((match, index) => (
                        <>
                          <Accordion key={match.matchId}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header
                                onClick={() => {
                                  // allMarketsHandler(match.matchId)
                                  window.location.href = `/dashboard/all-market/cricket/${match.eventName}/${match.eventId}/${match.matchId}`;
                                }}
                              >
                                {match.eventName} -{" "}
                                {moment(match?.startDate).format(
                                  "Do MMM, h:mm: a"
                                )}
                                <div className="header_wrap">
                                  <h6
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOpenEditBetModal(
                                        c.seriesId,
                                        "MATCH"
                                      );
                                    }}
                                  >
                                    {match.minStack ? match.minStack : "0"} -{" "}
                                    {match.maxStack ? match.maxStack : "0"}
                                  </h6>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label={
                                      match.betLock
                                        ? "Bet Locked"
                                        : "Bet Unlocked"
                                    }
                                    checked={match.betLock}
                                    onChange={(e) =>
                                      handleSwitchChange(
                                        e,
                                        match,
                                        "Match_BetStatus"
                                      )
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label={
                                      match.enabled ? "Enabled" : "Disabled"
                                    }
                                    checked={match.enabled}
                                    onChange={(e) =>
                                      handleSwitchMatchChange(e, match)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </div>
                              </Accordion.Header>
                            </Accordion.Item>
                          </Accordion>
                        </>
                      ))}
                    </Accordion.Body>  */}
                  </Accordion.Item>
                </Accordion>
              ))}
            </>
          )}
        </Tab>
      </Tabs>

      <ConfirmModal
        show={showConfrimModal}
        handleClose={handleCloseConfirmModal}
        handleConfirm={handleConfirm}
      >
        <h5>
          Are you sure you want to{" "}
          <strong>{confirmSeriesDetails?.confirmValue}</strong> this{" "}
          <strong>{confirmSeriesDetails?.eventName}</strong>?
        </h5>
      </ConfirmModal>
      {/*  <ConfirmModal
        show={showConfrimMatchModal}
        handleClose={handleCloseConfirmMatchModal}
        handleConfirm={matchEnableDisabledHandler}
      >
        <h5>
          Are you sure you want to{" "}
          <strong>{confirmMatchDetails?.confirmValue}</strong> this{" "}
          <strong>{confirmMatchDetails?.matchName}</strong>?
        </h5>
      </ConfirmModal> */}
      <CustomModal
        show={showEditBetModal}
        handleClose={handleCloseEditBetModal}
        onSubmit={onSubmitBetHandler}
        title="Edit Match Bet"
        isError={errorMessage}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Min Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter min bet value"
              name="minMarket"
              value={editBetData?.minMarket}
              onChange={handleMarketEditChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Max Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter max bet value"
              name="maxMarket"
              value={editBetData?.maxMarket}
              onChange={handleMarketEditChange}
            />
          </Form.Group>
        </Form>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </CustomModal>
      <CustomModal
        show={showMarketSettelModal}
        handleClose={handleCloseMarketSettelModal}
        onSubmit={settleMarketHandler}
        title="Market Settelment"
        isError={errorMessage}
        settelmentData={settelmentData}
        fromSettel
        isRollback={marketSettlmentType === "rollback"}
      >
        {marketSettlmentType !== "rollback" ? (
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              {settelmentData?.marketName
                ?.toLowerCase()
                .includes(marketNameTemp.toLowerCase()) ? (
                <FloatingLabel controlId="floatingSelect" label="Select Winner">
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={handleChangeWinner}
                  >
                    <option>Please select a winner</option>
                    {settelmentData?.subMarketInfo?.map((item) => (
                      <option
                        value={`${item.selectionId}-${item.runnerName}`}
                        key={item.selectionId}
                      >
                        {item.runnerName}
                      </option>
                    ))}
                    <option value="abandoned">Abandoned</option>
                    <option value="tie">Tie</option>
                  </Form.Select>
                </FloatingLabel>
              ) : (
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={"Session Passed"}
                  onChange={(e) => handleSwitchSettelChange(e)}
                />
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter comment"
                name="comment"
                value={settelmentData?.comment}
                onChange={handleChangeSettel}
              />
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        ) : (
          <h5>Are you sure you want to rollback</h5>
        )}
      </CustomModal>
      <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      />
    </>
  );
}
