import isEmpty from "is-empty";
import moment from "moment";
import React, { useEffect, useState, Suspense, useCallback } from "react";
import {
  Accordion,
  FloatingLabel,
  Form,
  FormControl,
  InputGroup,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { ConfirmModal } from "../Components/Modal/ConfirmModal";
import { CustomModal } from "../Components/Modal/CustomModal";
import { ToastMessage } from "../Components/Toast";
import { IoSearchSharp } from "react-icons/io5";
import {
  allMarkets,
  allSeriesSoccer,
  matchBetEnableDisabledSoccer,
  matchEnableDisabledSoccer,
  seriesBetEnableDisabledSoccer,
  seriesEnableDisabledSoccer,
  setMarketMaxMinBetSoccer,
  setMatchMaxMinBetSoccer,
  setSeriesMaxMinBetSoccer,
  settleMarket,
} from "./Service";
import AllMarketDataComponent from "./AllMarketData";
import { useHistory, useLocation } from "react-router";
import Loader from "../Components/Spinner";
import { searchEvent, searchEventSoccer } from "../Components/layout/Service";
import toast from "react-hot-toast";

const marketNameTemp = "Match Odds";

export default function EventManagementSoccer() {
  let history = useHistory();
  const location = useLocation();
  const [sportData, setSportData] = useState([]);
  const [showConfrimModal, setShowConfirmModal] = useState(false);
  const [showConfrimMatchModal, setShowConfirmMatchModal] = useState(false);
  const [confirmSeriesDetails, setConfirmSeriesDetails] = useState("");
  const [confirmMatchDetails, setConfirmMatchDetails] = useState("");
  const [allMarketData, setAllMarketData] = useState([]);
  const [showEditBetModal, setShowEditBetModal] = useState(false);
  const [editBetData, setEditBetData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isBetLockStatus, setIsBetLockStatus] = useState(false);
  const [isMatchBetLockStatus, setIsMatchBetLockStatus] = useState(false);
  const [isMarketBetLockStatus, setIsMarketBetLockStatus] = useState(false);
  const [isMarketEnableStatus, setIsMarketEnableStatus] = useState(false);
  const [isSeriesSetPrice, setIsSeriesSetPrice] = useState(false);
  const [isMatchSetPrice, setIsMatchSetPrice] = useState(false);
  const [errorMessage, setErroreMessage] = useState("");
  const [showMarketSettelModal, setShowMarketSettelModal] = useState(false);
  const [settelmentData, setSettelmentData] = useState({});
  const [isSessionPassed, setIsSessionPassed] = useState(false);
  const [winnerData, setWinnerData] = useState({});
  const [marketSettlmentType, setMarketSettlmentType] = useState("");
  const [matchRestult, setMatchResult] = useState("");
  const [selectedMatchId, setSelectedMatchId] = useState("");
  const [key, setKey] = useState("unsettled");
  const [isDataLoading, setDataLoading] = useState(false);
  const [cricketData, setCricketData] = useState({});
  const [eventNameQuery, setEventNameQuery] = useState("");

  const handleCloseMarketSettelModal = () => setShowMarketSettelModal(false);
  const handleOpenMarketSettelModal = (data, eventId, type, matchData) => {
    setShowMarketSettelModal(true);
    console.log(data, "checkData");
    setSettelmentData((prev) => ({ ...prev, ...data, eventId }));
    setMarketSettlmentType(type);

    setSelectedMatchId(matchData.matchId);
  };

  const handleCloseConfirmModal = () => setShowConfirmModal(false);
  const handleOpenConfirmModal = () => setShowConfirmModal(true);
  const handleCloseConfirmMatchModal = () => setShowConfirmMatchModal(false);
  const handleOpenConfirmMatachModal = () => setShowConfirmMatchModal(true);
  const handleCloseEditBetModal = () => setShowEditBetModal(false);
  const handleOpenEditBetModal = (id, type) => {
    console.log(id, "id", type, "type");
    if (type == "EVENT_MIN_MAX") {
      setEditBetData((prevState) => ({
        ...prevState,
        matchId: id,
        event: "EVENT_MIN_MAX",
      }));
      setIsMatchSetPrice(true);
      setIsSeriesSetPrice(false);
    }
    if (type === "SERIES") {
      setIsSeriesSetPrice(true);
      setIsMatchSetPrice(false);
      setEditBetData((prevState) => ({
        ...prevState,
        seriesId: id,
        event: "EVENT_MIN_MAX",
      }));
    } else if (type === "MATCH_MIN_MAX") {
      setIsMatchSetPrice(true);
      setIsSeriesSetPrice(false);
      setEditBetData({
        marketId: id,
        event: type,
      });
    } else {
      setIsSeriesSetPrice(false);
      setIsMatchSetPrice(false);
      setEditBetData((prevState) => ({ ...prevState, marketId: id }));
    }

    setShowEditBetModal(true);
  };

  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);

  const getAllSeries = useCallback(async () => {
    const res = await allSeriesSoccer(key === "settled" ? true : false);
    console.log(res, "resDataR");
    if (res.code === 200) {
      //   onSubmitUserHandler(res.response);
      setSportData(res.response);
      setDataLoading(false);
    }
  }, [key]);

  useEffect(() => {
    getAllSeries();
  }, [getAllSeries]);

  const confirmStatusUpdate = (data, type) => {
    if (type === "BET") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.betLock ? "Enabled Bet" : "Disabled Bet",
        seriesName: data.competitionsName,
        seriesId: data.seriesId,
        betEnable: !data.betLock,
      }));
    } else if (type === "MATCH_BET_STATUS") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled Bet" : "Disabled Bet",
        matchName: data.eventName,
        matchId: data.id,
        betEnable: !data.betLock,
        event: type,
      }));
    } else if (type == "MATCH_STATUS") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        matchName: data.eventName,
        eventId: data.id,
        enabled: !data.enabled,
        event: type,
      }));
    } else if (type === "MARKET") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled Bet" : "Disabled Bet",
        matchName: data.eventName,
        marketId: data.mktId,
        betEnable: !data.betLock,
      }));
    } else if (type === "MARKET_ENABLE") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        seriesName: data.competitionsName,
        marketId: data.mktId,
        isEnable: !data.enabled,
      }));
    } else if (type == "EVENT_BET_LOCK") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        matchName: data.eventName,
        eventId: data.id,
        enabled: !data.enabled,
        event: type,
        betLock: !data.betLock,
        type: "EVENT_BET_LOCK",
      }));
    } else if (type == "EVENT_ENABLE_DISABLE") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        matchName: data.eventName,
        eventId: data.id,
        enabled: !data.enabled,
        event: type,
        betLock: !data.betLock,
        type: "EVENT_ENABLE_DISABLE",
      }));
    } else if (type == "SERIES_BET_LOCK") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.betLock ? "Enabled Bet" : "Disabled Bet",
        seriesName: data.competitionsName,
        seriesId: data.seriesId,
        betLock: !data.betLock,
        event: "SERIES_BET_LOCK",
        type: "SERIES_BET_LOCK",
      }));
    } else if (type == "SERIES_ENABLE_DISABLE") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.betLock ? "Enabled Series" : "Disabled Series",
        seriesName: data.competitionsName,
        seriesId: data.seriesId,
        active: !data.active,
        event: "SERIES_ENABLE_DISABLE",
        type: "SERIES_ENABLE_DISABLE",
      }));
    } else {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        seriesName: data.competitionsName,
        seriesId: data.seriesId,
        isEnable: !data.enabled,
      }));
    }
  };

  const handleSwitchChange = (e, data, type) => {
    e.stopPropagation();
    console.log(type, "type");
    if (type == "MATCH_STATUS") {
      confirmStatusUpdate(data, "MATCH_STATUS");
    }
    if (type === "MATCH_BET_STATUS") {
      confirmStatusUpdate(data, "MATCH_BET_STATUS");
    }
    if (type === "EVENT_BET_LOCK") {
      confirmStatusUpdate(data, "EVENT_BET_LOCK");
    }
    if (type == "EVENT_ENABLE_DISABLE") {
      confirmStatusUpdate(data, "EVENT_ENABLE_DISABLE");
    }
    if (type === "SERIES_BET_LOCK") {
      confirmStatusUpdate(data, "SERIES_BET_LOCK");
    }
    if (type === "SERIES_ENABLE_DISABLE") {
      confirmStatusUpdate(data, "SERIES_ENABLE_DISABLE");
    }
    handleOpenConfirmModal();

    /* if (type === "Series_BetStatus") {
      setIsMarketBetLockStatus(false);
      handleOpenConfirmModal();
      confirmStatusUpdate(data, "BET");
      setIsMarketEnableStatus(false);

      setIsBetLockStatus(true);
      setIsMatchBetLockStatus(false);
    } else if (type === "Series_Enable") {
      setIsMarketBetLockStatus(false);
      handleOpenConfirmModal();
      setIsBetLockStatus(false);
      setIsMarketEnableStatus(false);

      setIsMatchBetLockStatus(false);
      if (data.enabled) {
        confirmStatusUpdate(data);
      } else {
        confirmStatusUpdate(data);
      }
    } else if (type === "Market_Enable") {
      setIsMarketBetLockStatus(false);
      handleOpenConfirmModal();
      setIsBetLockStatus(false);
      setIsMatchBetLockStatus(false);
      setIsMarketEnableStatus(true);
      confirmStatusUpdate(data, "MARKET_ENABLE");
    } else if (type === "Market_BetStatus") {
      setIsMarketBetLockStatus(true);
      setIsMatchBetLockStatus(false);
      setIsMarketEnableStatus(false);
      setIsBetLockStatus(false);
      confirmStatusUpdate(data, "MARKET");
      handleOpenConfirmMatachModal();
    } else if (type === "Match_BetStatus") {
      setIsMarketBetLockStatus(false);
      setIsMatchBetLockStatus(true);
      setIsBetLockStatus(false);
      setIsMarketEnableStatus(false);
      confirmStatusUpdate(data, "MATCH");
      handleOpenConfirmMatachModal();
    } */
  };

  const handleSwitchMatchChange = (e, data, event) => {
    e.stopPropagation();
    console.log(data, "checkData");
    handleOpenConfirmMatachModal();
    if (data.enabled) {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: "Enabled",
        matchName: data.eventName,
        matchId: data.matchId,
        isEnabled: data.enabled,
        event,
      }));
    } else {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: "Disabled",
        matchName: data.eventName,
        matchId: data.matchId,
        isEnabled: !data.enabled,
        event,
      }));
    }
  };

  const seriesEnableDisabledHandler = async (data, type) => {
    var seriesId = data.seriesId;
    var flag = data.betLock;
    let res;
    if (type == "SERIES_BET_LOCK") {
      res = await seriesBetEnableDisabledSoccer(seriesId, flag);
    }
    if (type == "SERIES_ENABLE_DISABLE") {
      res = await seriesEnableDisabledSoccer(seriesId, data.active);
    }
    if (res.code === 200) {
      getAllSeries();
      setShowConfirmModal(false);
    }
  };

  const handleConfirm = async () => {
    const {
      isEnabled,
      eventId,
      betEnable,
      marketId,
      matchId,
      event,
      enabled,
      betLock,
    } = confirmMatchDetails ? confirmMatchDetails : confirmSeriesDetails;

    let res;

    if (event === "MATCH_STATUS") {
      res = await matchEnableDisabledSoccer(eventId, enabled);
    }
    if (event === "MATCH_BET_STATUS") {
      res = await matchBetEnableDisabledSoccer(matchId, betEnable);
    }
    if (event === "EVENT_BET_LOCK") {
      res = await matchBetEnableDisabledSoccer(eventId, betLock);
    }
    if (event == "EVENT_ENABLE_DISABLE") {
      res = await matchEnableDisabledSoccer(eventId, enabled);
    }
    if (event === "SERIES_BET_LOCK" || "SERIES_ENABLE_DISABLE") {
      res = await seriesEnableDisabledHandler(confirmSeriesDetails, event);
    }

    getAllSeries();
    setShowConfirmModal(false);
  };

  const allMarketsHandler = async (id) => {
    const res = await allMarkets(id);

    if (res.code === 200) {
      setAllMarketData(res.response);
    }

    console.log(res, "resData");
  };

  const handleMarketEditChange = (e) => {
    const { name, value } = e.target;
    setErroreMessage("");

    setEditBetData((prevState) => {
      console.log(prevState, "checkPRev");
      return { ...prevState, [name]: value };
    });
  };

  const onSubmitBetHandler = async () => {
    const { maxMarket, minMarket, marketId, seriesId, matchId } = editBetData;
    if (parseInt(minMarket) > parseInt(maxMarket)) {
      setErroreMessage("Invalid input!");
      return;
    } else {
      setErroreMessage("");
    }
    let res;
    if (editBetData.seriesId) {
      res = await setSeriesMaxMinBetSoccer(maxMarket, minMarket, seriesId);
    } else {
      switch (editBetData.event) {
        case "EVENT_MIN_MAX":
          res = await setMatchMaxMinBetSoccer(maxMarket, minMarket, matchId);
          break;
        case "MATCH_MIN_MAX":
          res = await setMarketMaxMinBetSoccer(maxMarket, minMarket, marketId);
          break;
      }
    }
    /*  if (isSeriesSetPrice) {
      res = await setSeriesMaxMinBet(maxMarket, minMarket, seriesId);
    } else if (isMatchSetPrice) {
      res = await setMatchMaxMinBet(maxMarket, minMarket, matchId);
    } else {
      res = await setMarketMaxMinBet(maxMarket, minMarket, marketId);
    } */
    if (res?.code === 200) {
      getAllSeries();
      handleShowToast();
      toast.success("Bet updated successfully!");
      handleCloseEditBetModal();
    }
  };

  const handleSwitchSettelChange = (e) => {
    e.stopPropagation();

    setIsSessionPassed(e.target.checked);
  };

  const handleChangeSettel = (e) => {
    const { name, value } = e.target;
    setSettelmentData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeWinner = (e) => {
    const { value } = e.target;
    if (value === "abandoned" || value === "tie") {
      return setMatchResult(value);
    } else {
      setMatchResult("");
    }
    const selectionId = value.split("-")[0];
    const winnerName = value.split("-")[1];
    setWinnerData((prev) => ({
      ...prev,
      selectionId: selectionId,
      winnerName,
    }));
  };

  const settleMarketHandler = async () => {
    const { marketName, eventId, marketId, comment } = settelmentData;
    const { selectionId, winnerName } = winnerData;

    //settle or rollback

    const payload = {
      eventId,
      marketId,
      marketName,
      settlementComment: comment,
      winnerName: winnerName,
      winnerSelectionId: selectionId,
      eventType: marketSettlmentType,
    };
    if (!isEmpty(matchRestult)) {
      payload[matchRestult] = true;
      delete payload.winnerName;
    }

    if (!marketName.includes(marketNameTemp)) {
      payload.isSessionPassed = isSessionPassed;
      delete payload.winnerName;
      delete payload.winnerSelectionId;
    }

    const res = await settleMarket(payload);

    console.log(res, "resData");
    handleCloseMarketSettelModal();
    handleShowToast();
    setToastMessage(res.message);
    setSettelmentData({});
    setSettelmentData({});
    setIsSessionPassed(false);
    //selectedMatchId
    allMarketsHandler(selectedMatchId);
  };

  const handleEventChange = (e) => {
    if (e.key === "Enter") {
      searchEventHanlder();
    }
    setEventNameQuery(e.target.value);
  };

  const searchEventHanlder = async () => {
    let res;
    if (location.pathname.includes("event-management/cricket")) {
      res = await searchEvent(eventNameQuery);
    } else if (location.pathname.includes("event-management/soccer")) {
      res = await searchEventSoccer(eventNameQuery);
    }

    if (res.code === 200) {
      setSportData(res.response);
    }
  };

  return (
    <>
      {/* <header class="mb-3">
        <a href="#" class="burger-btn d-block d-xl-none">
          <i class="bi bi-justify fs-3"></i>
        </a>
      </header> */}
      <InputGroup
        style={{
          width: "50%",
          margin: 10,
          marginLeft: "auto",
        }}
      >
        <FormControl
          placeholder="Search..."
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          onChange={handleEventChange}
        />
        <InputGroup.Text
          id="basic-addon2"
          onClick={searchEventHanlder}
          style={{ cursor: "pointer" }}
        >
          <IoSearchSharp />
        </InputGroup.Text>
      </InputGroup>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="settled" title="Settled">
          {isDataLoading ? (
            <Loader />
          ) : (
            <>
              {sportData.map((c, index) => (
                <Accordion defaultActiveKey={0}>
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      {c.seriesName}{" "}
                      <div className="header_wrap">
                        <h6
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditBetModal(c.gameId, "EDIT_MIN_MAX");
                          }}
                        >
                          {c.minStack ? c.minStack : "0"} -{" "}
                          {c.maxStack ? c.maxStack : "0"}
                        </h6>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.betLock ? "Bet Locked" : "Bet Unlocked"}
                          checked={c.betLock}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "SERIES_BET_LOCK")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.enabled ? "Enabled" : "Disabled"}
                          checked={c.enabled}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "SERIES_ENABLE_DISABLE")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Accordion.Body>
                        {c?.eventDTOList?.map((match, index) => (
                          <>
                            <Accordion key={match.matchId}>
                              <Accordion.Item eventKey={index}>
                                <Accordion.Header
                                  onClick={() => {
                                    // allMarketsHandler(match.matchId)
                                    window.location.href = `/dashboard/all-market/soccer/${match.eventName?.replaceAll(
                                      "/",
                                      ""
                                    )}/${match.eventId}/${match.id}`;
                                  }}
                                >
                                  {match.eventName} -{" "}
                                  {moment(match?.startDate).format(
                                    "Do MMM, h:mm: a"
                                  )}
                                  <div className="header_wrap">
                                    <h6
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenEditBetModal(
                                          match.id,
                                          "EVENT_MIN_MAX"
                                        );
                                      }}
                                    >
                                      {match.minStack ? match.minStack : "0"} -{" "}
                                      {match.maxStack ? match.maxStack : "0"}
                                    </h6>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label={
                                        match.betLock
                                          ? "Bet Locked"
                                          : "Bet Unlocked"
                                      }
                                      checked={match.betLock}
                                      onChange={(e) =>
                                        handleSwitchChange(
                                          e,
                                          match,
                                          "EVENT_BET_LOCK"
                                        )
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label={
                                        match.enabled ? "Enabled" : "Disabled"
                                      }
                                      checked={match.enabled}
                                      onChange={(e) =>
                                        handleSwitchMatchChange(
                                          e,
                                          match,
                                          "EVENT_ENABLE_DISABLE"
                                        )
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </div>
                                </Accordion.Header>
                              </Accordion.Item>
                            </Accordion>
                          </>
                        ))}
                      </Accordion.Body>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
              {/* sportData.map((c, i) => (
                <Accordion defaultActiveKey={0} key={c.gameId}>
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>
                      {c.eventName}
                      <div className="header_wrap">
                        <h6
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditBetModal(c.gameId, "EDIT_MIN_MAX");
                          }}
                        >
                          {c.minStack ? c.minStack : "0"} -{" "}
                          {c.maxStack ? c.maxStack : "0"}
                        </h6>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.betLock ? "Bet Locked" : "Bet Unlocked"}
                          checked={c.betLock}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "Series_BetStatus")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.enabled ? "Enabled" : "Disabled"}
                          checked={c.enabled}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "Series_Enable")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {c.cricketChildEvents.map((match, index) => (
                        <>
                          <Accordion key={match.matchId}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header
                                onClick={() => {
                                  // allMarketsHandler(match.matchId)
                                  window.location.href = `/dashboard/all-market/cricket/${match.eventName}/${match.eventId}/${match.matchId}`;
                                }}
                              >
                                {match.eventName} -{" "}
                                {moment(match?.startDate).format(
                                  "Do MMM, h:mm: a"
                                )}
                                <div className="header_wrap">
                                  <h6
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOpenEditBetModal(
                                        c.seriesId,
                                        "MATCH"
                                      );
                                    }}
                                  >
                                    {match.minStack ? match.minStack : "0"} -{" "}
                                    {match.maxStack ? match.maxStack : "0"}
                                  </h6>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label={
                                      match.betLock
                                        ? "Bet Locked"
                                        : "Bet Unlocked"
                                    }
                                    checked={match.betLock}
                                    onChange={(e) =>
                                      handleSwitchChange(
                                        e,
                                        match,
                                        "Match_BetStatus"
                                      )
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label={
                                      match.enabled ? "Enabled" : "Disabled"
                                    }
                                    checked={match.enabled}
                                    onChange={(e) =>
                                      handleSwitchMatchChange(e, match)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </div>
                              </Accordion.Header>
                            </Accordion.Item>
                          </Accordion>
                        </>
                      ))}
                    </Accordion.Body> 
                  </Accordion.Item>
                </Accordion>
              )) */}
            </>
          )}
        </Tab>
        <Tab eventKey="unsettled" title="Unsettled">
          {isDataLoading ? (
            <Loader />
          ) : (
            <>
              {sportData.map((c, index) => (
                <Accordion defaultActiveKey={0}>
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      {c.seriesName}{" "}
                      <div className="header_wrap">
                        <h6
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditBetModal(c.seriesId, "SERIES");
                          }}
                        >
                          {c.minStack ? c.minStack : "0"} -{" "}
                          {c.maxStack ? c.maxStack : "0"}
                        </h6>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.betLock ? "Bet Locked" : "Bet Unlocked"}
                          checked={c.betLock}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "SERIES_BET_LOCK")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.enabled ? "Enabled" : "Disabled"}
                          checked={c.enabled}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "SERIES_ENABLE_DISABLE")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Accordion.Body>
                        {c?.eventDTOList?.map((match, index) => (
                          <>
                            <Accordion key={match.matchId}>
                              <Accordion.Item eventKey={index}>
                                <Accordion.Header
                                  onClick={() => {
                                    // allMarketsHandler(match.matchId)
                                    window.location.href = `/dashboard/all-market/soccer/${match.eventName?.replaceAll(
                                      "/",
                                      ""
                                    )}/${match.eventId}/${match.id}`;
                                  }}
                                >
                                  {match.eventName} -{" "}
                                  {moment(match?.startDate).format(
                                    "Do MMM, h:mm: a"
                                  )}
                                  <div className="header_wrap">
                                    <h6
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenEditBetModal(
                                          match.id,
                                          "EVENT_MIN_MAX"
                                        );
                                      }}
                                    >
                                      {match.minStack ? match.minStack : "0"} -{" "}
                                      {match.maxStack ? match.maxStack : "0"}
                                    </h6>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label={
                                        match.betLock
                                          ? "Bet Locked"
                                          : "Bet Unlocked"
                                      }
                                      checked={match.betLock}
                                      onChange={(e) =>
                                        handleSwitchChange(
                                          e,
                                          match,
                                          "EVENT_BET_LOCK"
                                        )
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label={
                                        match.enabled ? "Enabled" : "Disabled"
                                      }
                                      checked={match.enabled}
                                      onChange={(e) =>
                                        handleSwitchMatchChange(
                                          e,
                                          match,
                                          "EVENT_ENABLE_DISABLE"
                                        )
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </div>
                                </Accordion.Header>
                              </Accordion.Item>
                            </Accordion>
                          </>
                        ))}
                      </Accordion.Body>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}

              {/*   {sportData.map((c, i) => (
                <Accordion defaultActiveKey={0} key={c.gameId}>
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>
                      {c.eventName}
                      <div className="header_wrap">
                        <h6
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditBetModal(c.id, "EVENT_MIN_MAX");
                          }}
                        >
                          {c.minStack ? c.minStack : "0"} -{" "}
                          {c.maxStack ? c.maxStack : "0"}
                        </h6>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.betLock ? "Bet Locked" : "Bet Unlocked"}
                          checked={c.betLock}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "MATCH_BET_STATUS")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label={c.enabled ? "Enabled" : "Disabled"}
                          checked={c.enabled}
                          onChange={(e) =>
                            handleSwitchChange(e, c, "MATCH_STATUS")
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </Accordion.Header>
                     <Accordion.Body>
                      {c.cricketChildEvents.map((match, index) => (
                        <>
                          <Accordion key={match.matchId}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header
                                onClick={() => {
                                  // allMarketsHandler(match.matchId)
                                  window.location.href = `/dashboard/all-market/cricket/${match.eventName}/${match.eventId}/${match.matchId}`;
                                }}
                              >
                                {match.eventName} -{" "}
                                {moment(match?.startDate).format(
                                  "Do MMM, h:mm: a"
                                )}
                                <div className="header_wrap">
                                  <h6
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOpenEditBetModal(
                                        c.seriesId,
                                        "MATCH"
                                      );
                                    }}
                                  >
                                    {match.minStack ? match.minStack : "0"} -{" "}
                                    {match.maxStack ? match.maxStack : "0"}
                                  </h6>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label={
                                      match.betLock
                                        ? "Bet Locked"
                                        : "Bet Unlocked"
                                    }
                                    checked={match.betLock}
                                    onChange={(e) =>
                                      handleSwitchChange(
                                        e,
                                        match,
                                        "Match_BetStatus"
                                      )
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label={
                                      match.enabled ? "Enabled" : "Disabled"
                                    }
                                    checked={match.enabled}
                                    onChange={(e) =>
                                      handleSwitchMatchChange(e, match)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </div>
                              </Accordion.Header>
                            </Accordion.Item>
                          </Accordion>
                        </>
                      ))}
                    </Accordion.Body>  
                  </Accordion.Item>
                </Accordion>
              ))} */}
            </>
          )}
        </Tab>
      </Tabs>

      <ConfirmModal
        show={showConfrimModal}
        handleClose={handleCloseConfirmModal}
        handleConfirm={handleConfirm}
      >
        <h5>
          Are you sure you want to{" "}
          <strong>{confirmSeriesDetails?.confirmValue}</strong> this{" "}
          <strong>{confirmSeriesDetails?.eventName}</strong>?
        </h5>
      </ConfirmModal>
      <ConfirmModal
        show={showConfrimMatchModal}
        handleClose={handleCloseConfirmMatchModal}
        handleConfirm={handleConfirm}
      >
        <h5>
          Are you sure you want to{" "}
          <strong>{confirmMatchDetails?.confirmValue}</strong> this{" "}
          <strong>{confirmMatchDetails?.matchName}</strong>?
        </h5>
      </ConfirmModal>
      <CustomModal
        show={showEditBetModal}
        handleClose={handleCloseEditBetModal}
        onSubmit={onSubmitBetHandler}
        title="Edit Match Bet"
        isError={errorMessage}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Min Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter min bet value"
              name="minMarket"
              value={editBetData?.minMarket}
              onChange={handleMarketEditChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Max Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter max bet value"
              name="maxMarket"
              value={editBetData?.maxMarket}
              onChange={handleMarketEditChange}
            />
          </Form.Group>
        </Form>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </CustomModal>
      <CustomModal
        show={showMarketSettelModal}
        handleClose={handleCloseMarketSettelModal}
        onSubmit={settleMarketHandler}
        title="Market Settelment"
        isError={errorMessage}
        settelmentData={settelmentData}
        fromSettel
        isRollback={marketSettlmentType === "rollback"}
      >
        {marketSettlmentType !== "rollback" ? (
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              {settelmentData?.marketName
                ?.toLowerCase()
                .includes(marketNameTemp.toLowerCase()) ? (
                <FloatingLabel controlId="floatingSelect" label="Select Winner">
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={handleChangeWinner}
                  >
                    <option>Please select a winner</option>
                    {settelmentData?.subMarketInfo?.map((item) => (
                      <option
                        value={`${item.selectionId}-${item.runnerName}`}
                        key={item.selectionId}
                      >
                        {item.runnerName}
                      </option>
                    ))}
                    <option value="abandoned">Abandoned</option>
                    <option value="tie">Tie</option>
                  </Form.Select>
                </FloatingLabel>
              ) : (
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={"Session Passed"}
                  onChange={(e) => handleSwitchSettelChange(e)}
                />
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter comment"
                name="comment"
                value={settelmentData?.comment}
                onChange={handleChangeSettel}
              />
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        ) : (
          <h5>Are you sure you want to rollback</h5>
        )}
      </CustomModal>
      <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      />
    </>
  );
}
